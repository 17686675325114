import { Global } from '@emotion/core';
import React from 'react';
import Helmet from 'react-helmet';


// @ts-ignore
import favicon from '../../src/favicon.ico';
import { GlobalStyles } from './globalStyles';

interface IndexProps {
  className?: string;
  children?: React.ReactNode
}

const IndexLayout: React.FC<IndexProps> = ({ className, children }: IndexProps) => {
  return (
    <div className={className}>
      <Helmet>
        <link rel="icon" href={favicon} type="image/x-icon" />
      </Helmet>
      <Global styles={GlobalStyles} />
      {children}
    </div>
  );
};

export default IndexLayout;
