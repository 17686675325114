export interface WebsiteConfig {
  title: string;
  description: string;
  author: string;
  coverImage: string;
  logo: string;
  /**
   * Specifying a valid BCP 47 language helps screen readers announce text properly.
   * See: https://dequeuniversity.com/rules/axe/2.2/valid-lang
   */
  lang: string;
  /**
   * blog full path, no ending slash!
   */
  siteUrl: string;
  /**
   * full url, no username
   */
  facebook?: string;
  linkedin?: string;
  github?: string;
  twitter?: string;
  showSubscribe: boolean;
  /**
   * create a list on mailchimp and then create an embeddable signup form. this is the form action
   */
  mailchimpAction?: string;
  /**
   * this is the hidden input field name
   */
  mailchimpName?: string;
  /**
   * name and id of the mailchimp email field
   */
  mailchimpEmailFieldName?: string;
  /**
  /**
   * Meta tag for Google Webmaster Tools
   */
  googleSiteVerification?: string;
  /**
  /**
   * Appears alongside the footer, after the credits
   */
  footer?: string;
}

const config: WebsiteConfig = {
  title: 'Nicknacks',
  author: 'Nicholas Vincent-Hill',
  description: 'by Nick Vincent-Hill',
  coverImage: 'img/home_background.jpg',
  logo: 'img/logo.png',
  lang: 'en',
  siteUrl: 'https://nicknacks.netlify.com', // TODO
  linkedin: 'https://www.linkedin.com/in/nvincenthill',
  github: 'https://github.com/nvincenthill',
  showSubscribe: true,
  mailchimpAction: 'http://eepurl.com/gHwUI9',
  mailchimpName: 'b_a89b6987ac248c81b0b7f3a0f_7d777b7d75', // TODO
  mailchimpEmailFieldName: 'MERGE0',
  googleSiteVerification: 'GoogleCode',
  footer: 'is based on Gatsby Casper',
};

export default config;
